import React from "react";

import { Link } from "react-router-dom";

const Nav = () => {
  // let Links = [
  //   // { name: "Home", link: "/" },
  //   // { name: "Products", link: "/product" },
  //   // { name: "About", link: "/about" },
  //   // { name: "Contact", link: "/contact" },
  //   // { name: "Blog", link: "/blog" },
  // ];
  // let [open, setOpen] = useState(false);

  return (
    <div>
      <nav className=" w-full fixed bg-black bg-opacity-75 backdrop-blur-xl border-gray-800 navbar border-b ">
        <div className="md:flex  items-center justify-between  py-4 md:px-10 px-4 flex  ">
          <div
            className="font-bold text-2xl cursor-pointer flex items-center font-[Poppins] 
      text-gray-800"
          >
            <Link to="/" className="text-white text-xl lg:text-2xl">
              Mini Brain
              <br />
            </Link>
          </div>

          {/* <div
            onClick={() => setOpen(!open)}
            className="text-3xl absolute right-8 top-6 cursor-pointer md:hidden text-white"
          >
            <ion-icon name={open ? "close" : "menu"}></ion-icon>
          </div>

          <ul
            className={`md:flex md:items-center  md:pb-0   lg:bg-transparent xl:bg-transparent md:bg-transparent   absolute md:static  md:z-auto z-[-1] left-0 w-full md:w-auto md:pl-0  transition-all duration-500 ease-in ${
              open ? "top-20 opacity-500 bg-black" : "top-[-490px]"
            } md:opacity-500 opacity-500`}
          >
            {Links.map((link) => (
              <li
                key={link.name}
                className="md:ml-8 pl-5 xl:pl-0 md:pl-0 lg:pl-0  text-md md:my-0 my-7"
              >
                <Link
                  to={link.link}
                  className="navlink px-2  py-2 text-md hover:text-gray-300"
                >
                  {link.name}
                </Link>
              </li>
            ))}
          </ul> */}
          <Link
            to="/product"
            className="items-center lg:px-5 lg:py-2 md:px-5 md:py-2  px-4 py-2 rounded-lg bg-white float-right "
          >
            <span className="font-medium title-font text-black text-sm lg:text-[14px]">
              Get Started
            </span>
          </Link>
        </div>
      </nav>
    </div>
  );
};

export default Nav;
