import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div>
      <div className="bg-black">
        <div className="max-w-screen-lg py-10 px-4 sm:px-6 text-gray-300 sm:flex justify-between mx-auto">
          <div className="p-5 sm:w-3/12 border-r">
            <div className="text-sm uppercase text-black font-bold">Menu</div>
            <ul>
              <li className="my-2">
                <Link className="hover:text-gray-500 text-sm" to="/">
                  Home
                </Link>
              </li>
              <li className="my-2">
                <Link className="hover:text-gray-500 text-sm" to="/product">
                  Products
                </Link>
              </li>
              <li className="my-2">
                <Link
                  className="hover:text-gray-500 text-sm"
                  to="/terms&conditions"
                >
                  Terms and conditions
                </Link>
              </li>
              <li className="my-2">
                <Link
                  className="hover:text-gray-500 text-sm"
                  to="/PrivacyPolicy"
                >
                  Privacy Policy
                </Link>
              </li>

              <li className="my-2">
                <Link
                  className="hover:text-gray-500 text-sm"
                  to="/pages/account-deletion-policy"
                >
                  Account Deletion Policy
                </Link>
              </li>
            </ul>
          </div>
          <div className="p-5 sm:w-7/12 border-r text-center">
            <h3 className="font-bold text-xl text-white mb-4">
              Mini Brain Pvt. Ltd.
            </h3>
            <p className="text-gray-500 text-sm mb-10">
              Being a part of this mini brain  you will be able to interact
              with our different products.
            </p>
          </div>
          <div className="p-5 sm:w-3/12">
            <div className="text-sm uppercase text-black font-bold">
              Contact Us
            </div>
            <ul>
              <li className="my-2">
                <h1 className="font-bold text-white">Address</h1>

                <div className="hover:text-gray-500 font-thin">
                  {/* 102, Plot no. 1204, Sri Sai Nilayam - A Block , Ayyappa
                  Society , Madhapur, Hyderabad, Telengana 500081 */}
                  P.No. 155,Part 156 P, Farzanguda, Secunderabad, Alwal, Tirumalagiri,
                  HYDERABAD,TELENGANA 500010
                </div>
              </li>
              <li className="my-2">
                <h1 className="font-bold text-white">Contact Us</h1>
                <a className="hover:text-gray-500 font-thin" href="/#">
                  support@minibrain.net
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="flex py-5 m-auto text-gray-800 text-sm flex-col items-center border-t max-w-screen-xl">
          <div className="md:flex-auto md:flex-row-reverse mt-2 flex-row flex">
            {/* <div className="flex sm:justify-center xl:justify-start">
        <a
          href="/"
          className="w-8 h-8  border-2 border-gray-400 rounded-full text-center py-1 text-gray-600 hover:text-white hover:bg-blue-600 hover:border-blue-600"
        >
          <i className="fab fa-facebook"></i>
        </a>
        <a
          href="/"
          className="w-8 h-8  border-2 border-gray-400 rounded-full text-center py-1 ml-2 text-gray-600 hover:text-white hover:bg-blue-400 hover:border-blue-400"
        >
          <i className="fab fa-twitter"></i>
        </a>
        <a
          href="/"
          className="w-8 h-8  border-2 border-gray-400 rounded-full text-center py-1 ml-2 text-gray-600 hover:text-white hover:bg-red-600 hover:border-red-600"
        >
          <i className="fab fa-linkedin-in"></i>
        </a>

        <a
          href="/"
          className="w-8 h-8  border-2 border-gray-400 rounded-full text-center py-1 ml-2 text-gray-600 hover:text-white hover:bg-red-600 hover:border-red-600"
        >
          <i class="fab fa-youtube"></i>
        </a>
      </div> */}
          </div>
          <div className="my-5 text-white">
            © Copyright 2023. All Rights Reserved.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
