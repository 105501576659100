import React from "react";

const Terms = () => {
  return (
    <div className="max-w-screen-lg mx-auto px-4 py-20">
      <h1 className="text-3xl font-bold text-center mb-4">
        Terms and Conditions
      </h1>
      <div className=" leading-7 text-justify text-gray-800">
        <p className="my-4 text-black">
          Welcome to our software company official website. These Terms and
          Conditions of Use (the "Terms") govern your use of our website,
          including any content, features, or services offered on or through the
          website. By accessing or using our website, you agree to be bound by
          these Terms.
        </p>
        <h3 className="text-xl font-semibold text-black">
          Intellectual Property
        </h3>
        <p className="my-4">
          The content and materials on our website, including text, graphics,
          images, logos, and software, are owned by our company or its licensors
          and are protected by copyright, trademark, and other intellectual
          property laws. You may not copy, reproduce, modify, distribute, or
          display any part of our website without our prior written consent.
        </p>

        <h3 className="text-xl font-semibold text-black">User Content</h3>

        <p className="my-4">
          You are responsible for any content that you post or submit through
          our website, including text, graphics, images, and audio or video
          files. You grant our company a non-exclusive, transferable,
          sub-licensable, royalty-free, worldwide license to use, copy, modify,
          create derivative works based on, distribute, and display your content
          in connection with the website. You represent and warrant that you own
          or have the necessary rights and permissions to post or submit your
          content through our website, and that your content does not infringe
          the intellectual property or other rights of any third party.
        </p>
        <h3 className="text-xl font-semibold text-black">Changes to Terms</h3>
        <p className="my-4">
          We may update these Terms from time to time, and any changes will be
          effective immediately upon posting on our website. Your continued use
          of our website after any such changes constitutes your acceptance of
          the revised Terms.
        </p>
        <h3 className="text-xl font-semibold text-black">Termination</h3>
        <p className="my-4">
          We reserve the right to terminate your access to our website, without
          notice or liability, for any reason, including if we believe that you
          have violated these Terms or applicable law.
        </p>
        <p>
          If you have any questions about these Terms or our website, please
          contact us at support@minibrain.net .
        </p>
      </div>
    </div>
  );
};

export default Terms;
