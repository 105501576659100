import React from "react";
import Product from "../components/product";

function Products() {
  return (
    <>
      <div className="">
        <Product />
      </div>
    </>
  );
}

export default Products;
