import React from "react";
import Brands from "./brands";

function hero() {
  return (
    <>
      <section className="bg-black">
        <div className="w-full px-2 lg:pt-52 pt-36 mx-auto">
          <div className="mx-auto text-center mb-36">
            <h1 className="lg:leading-tight text-5xl lg:text-[85px]  font-bold text-transparent sm:text-5xl bg-clip-text bg-gradient-to-r from-green-300 via-cyan-500 to-cyan-800">
              Connecting <span className="text-green-300">Future</span>
              <br />
              Medical & Technologies
            </h1>
          </div>
          <Brands />
        </div>
      </section>
    </>
  );
}

export default hero;
