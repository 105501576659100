import React from "react";
import Hero from "../components/hero";
import Newsletter from "../components/newsletter";
import Product from "../components/product";

function Homepage() {
  return (
    <div>
      <Hero />
      <Product />
      <Newsletter />
    </div>
  );
}

export default Homepage;
