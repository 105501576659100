import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="max-w-screen-lg mx-auto px-4 py-20">
      <h1 className="text-3xl font-bold text-center mb-4">Privacy Policy</h1>
      <div className=" leading-7 text-justify text-gray-800">
        <p className="my-4 text-black">
          Our software company values your privacy and is committed to
          protecting your personal information. This Privacy Policy describes
          how we collect, use, and disclose information through our website. By
          accessing or using our website, you agree to the terms of this Privacy
          Policy. If you do not agree to this Privacy Policy, you should not
          access or use our website.
        </p>
        <h3 className="text-xl font-semibold text-black">
          Information We Collect
        </h3>
        <p className="my-4">
          We may collect personal information from you when you voluntarily
          provide it to us, such as when you contact us or submit a request
          through our website. The personal information we collect may include
          your name, email address, phone number, and other information you
          provide to us. In addition, we may automatically collect certain
          information about your use of our website, such as your IP address,
          browser type, device type, and the pages you visit on our website.
        </p>

        <h3 className="text-xl font-semibold text-black">Use of Information</h3>

        <p className="my-4">
          We may use the personal information we collect for the following
          purposes. To respond to your inquiries and provide you with customer
          support. To improve our website and services. To communicate with you
          about our products, services, and promotions. To comply with
          applicable laws and regulations.
        </p>
        <h3 className="text-xl font-semibold text-black">Security</h3>
        <p className="my-4">
          We use reasonable measures to protect your personal information from
          unauthorized access, disclosure, or alteration. However, no method of
          transmission over the internet or electronic storage is 100% secure,
          and we cannot guarantee the absolute security of your personal
          information.
        </p>
        <h3 className="text-xl font-semibold text-black">
          Changes to this Privacy Policy
        </h3>
        <p className="my-4">
          We may update this Privacy Policy from time to time, and any changes
          will be effective immediately upon posting on our website. Your
          continued use of our website after any such changes constitutes your
          acceptance of the revised Privacy Policy.
        </p>
        <p>
          If you have any questions about this Privacy Policy or our website,,
          please contact us at support@minibrain.net.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
