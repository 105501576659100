import React from "react";

function newsletter() {
  return (
    <div>
      <section className="w-full newsletter border-b">
        <div
          className="
          
          items-center
          w-full
          px-5
          py-12
          mx-auto
          md:px-12
          lg:px-16
          max-w-7xl
          lg:py-24
        "
        >
          <div className="flex w-full mx-auto text-left">
            <div className=" inline-flex items-center mx-auto align-middle">
              <div className="pb-12 text-center">
                <h1
                  className="
                  max-w-5xl
                  text-2xl
                  font-bold
                  leading-none
                  tracking-tighter
                  text-black
                  md:text-5xl
                  lg:text-7xl lg:max-w-7xl
                "
                >
                  {" "}
                  We have a newsletter, <br className="hidden lg:block" /> are
                  you in?{" "}
                </h1>
                <form
                  className="
                  p-2
                  mt-8
                 
                  border2
                  bg-gray-50
                  md:mx-auto
                  rounded-xl
                  sm:max-w-3xl
                  lg:flex
                  shadow-lg
                "
                >
                  <div className="divide-y lg:divide-x lg:divide-y-0 lg:flex space-y 4">
                    <div className="flex-1 min-w-0 revue-form-group">
                      <label for="name" className="sr-only">
                        Name
                      </label>
                      <input
                        id="name"
                        type="text"
                        className="
                        
                        w-full
                        px-5
                        py-3
                        text-base text-neutral-600
                        placeholder-gray-300
                       
                        bg-transparent
                        border border-transparent
                        rounded-md
                        focus:outline-none
                        focus:border-transparent
                        focus:ring-2
                        focus:ring-white
                        focus:ring-offset-2
                        focus:ring-offset-gray-300
                      "
                        placeholder="Enter your name "
                      />
                    </div>
                    <div className="flex-1 min-w-0 revue-form-group">
                      <label for="member_email" className="sr-only">
                        Email address
                      </label>
                      <input
                        id="cta-email"
                        type="email"
                        className="
                        
                        w-full
                        px-5
                        py-3
                        text-base text-neutral-600
                        placeholder-gray-300
                       
                        bg-transparent
                        border border-transparent
                        rounded-md
                        focus:outline-none
                        focus:border-transparent
                        focus:ring-2
                        focus:ring-white
                        focus:ring-offset-2
                        focus:ring-offset-gray-300
                      "
                        placeholder="Enter your email  "
                      />
                    </div>
                  </div>
                  <div className="mt-4 sm:mt-0 lg:ml-3 revue-form-actions">
                    <button
                      type="submit"
                      value="Subscribe"
                      name="member[subscribe]"
                      id="member_submit"
                      className="
                    
                      w-full
                      px-5
                      py-3
                      text-base
                      font-medium
                      text-white
                      bg-blue-600
                      border border-transparent
                      rounded-lg
                      shadow
                      hover:bg-blue-700
                      focus:outline-none
                      focus:ring-2
                      focus:ring-white
                      focus:ring-offset-2
                      focus:ring-offset-gray-300
                      sm:px-10
                    "
                    >
                      {" "}
                      Subscribe{" "}
                    </button>
                  </div>
                </form>
                <div className="sm:max-w-lg sm:flex md:mx-auto">
                  <p className="mt-3 text-xs text-gray-500">
                    {" "}
                    By subscribing, you agree with Mini Brain Pvt. Ltd. Terms
                    of Service and Privacy Policy
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default newsletter;
