import "./App.css";
import Homepage from "./pages/homepage";
import Navbar from "./components/navbar/navbar";
import Footer from "./components/footer";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Product from "./pages/product";
import ScrollToTop from "./components/navbar/scrollToTop";
import Terms from "./pages/terms";
import PrivacyPolicy from "./pages/privacyPolicy";
import AccountDeletionPolicy from "./pages/accountDeleteionPolicy";
function App() {
  return (
    <>
      <BrowserRouter>
        <ScrollToTop>
          <Navbar />
          <Routes>
            <Route exact path="/" element={<Homepage />} />
            <Route path="/product" element={<Product />} />
            <Route path="/terms&conditions" element={<Terms />} />
            <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
            <Route path="/pages/account-deletion-policy" element={<AccountDeletionPolicy />} />
          </Routes>
          <Footer />
        </ScrollToTop>
      </BrowserRouter>
    </>
  );
}

export default App;
