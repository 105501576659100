import React from "react";
import Logo from "../assets/images/logodw.svg";
import Logo3 from "../assets/images/dd1.png";

function brands() {
  return (
    <>
      <div className="py-6  flex flex-col items-center  overflow-hidden  bg-white">
        <div className=" container m-auto px-6 md:px-12">
          <div className="m-auto text-center md:w-full lg:w-full">
            <h2 className="text-3xl text-black font-bold md:text-5xl">
              Somethin<span className="text-gray-400">g</span> Awesom
              <span className="text-gray-400">e</span> Is In The Work
              <span className="text-gray-400">s</span>{" "}
            </h2>
          </div>
          <div className="m-auto mt-8 md:w-11/12 lg:w-12/12 xl:w-7/12">
            <div className="flex flex-wrap justify-center gap-6">
              <div className="group py-2 px-4 border border-opacity-10 rounded-xl bg-white bg-opacity-5 hover:bg-opacity-10">
                <img src={Logo} className="lg:h-20 h-24" alt="logo" />
              </div>
              <div className="group py-2 px-12 border border-opacity-10 rounded-xl bg-white bg-opacity-5 hover:bg-opacity-10">
                <img src={Logo3} className="lg:h-20 h-24" alt="logo" />
              </div>
              {/* <div className="group py-2 px-4 border border-opacity-10 rounded-xl bg-white bg-opacity-0 hover:bg-opacity-10">
                <img src={Logo2} className="h-24" alt="logo" />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default brands;
