import React from "react";

const AccountDeletionPolicy = () => {
  return (
    <div className="max-w-screen-lg mx-auto px-4 py-20">
      <h1 className="text-3xl font-bold text-center mb-4">Account Deletion Policy</h1>
      <div className=" leading-7 text-justify text-gray-800">
        <p className="my-4 text-black">
            You may choose to delete your account on our platform (e.g. website and /or mobile application)
            hosted at www.minibrain.net and any associated information at any time.
            To request deletion, please send a written request to support@minibrain.net.
            Upon deletion of your account or data, access to the platform and its services will be revoked,
            and we reserve the right to discontinue providing any services through the platform.
            Upon your request, we may either delete your information, both personal and non-personal,
            or anonymize it to render it non-attributable to you.
            We may retain some or all of your information as per our policies or legal requirements. For terms not defined herein,
            refer to our Privacy Policy.
        </p>
        
        

        
      </div>
    </div>
  );
};

export default AccountDeletionPolicy;
